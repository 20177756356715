import React from "react";
import PropTypes from "prop-types";
import { GlobalStyles } from "twin.macro";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "../css/slick/slick-theme.css";
import "../css/slick/slick.css";
// library.add(faEnvelope, faKey, faChevronUp);
// import {chev} from "@fortawesome/fontawesome-svg-core"
import { PrimaryNavbar, SecondaryNavbar, Footer } from "./Layout/index";
import "twin.macro";

// https://fontsource.org/#nunito-sans
import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/800.css";
import "@fontsource/nunito-sans/900.css";

import "./layout.css";

// https://www.gatsbyjs.com/docs/recipes/pages-layouts/#creating-a-layout-component
// https://www.gatsbyjs.com/docs/how-to/routing/layout-components/
// https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/
const Layout = ({ children, ...rest }) => (
  <div {...rest} tw="flex flex-col min-h-screen">
    <GlobalStyles />
    <div tw="bg-primary">
      <ScrollToTop
        aria-label="scroll to top"
        style={{ backgroundColor: "" }}
        className="scroll-to-top"
        component={
          <FontAwesomeIcon
            // style={{backgroundColor:"black", padding:"1.5rem", color:"white"}}
            tw=" w-full h-full text-white outline-none"
            icon={faChevronUp}
          />
        }
        smooth
        top={0}
      />
    </div>
    <SecondaryNavbar />
    <PrimaryNavbar />
    {/* HACK: font-black for everything that is not set explicitly */}
    <div tw="flex-grow overflow-x-hidden font-black">{children}</div>
    <Footer />
  </div>
);

// https://github.com/facebook/react/issues/5143#issuecomment-662150906
Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
