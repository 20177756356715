import React from "react";
import "twin.macro";

export default function SecondaryNavbar() {
  return (
    <div className="" tw="bg-primary px-4 py-3">
      <p
        className=""
        tw="text-white text-sm font-semibold text-center md:text-left tracking-wide"
      >
        <a href="tel:+4952132725140">Angebot einholen: 0521/32725140</a>
      </p>
    </div>
  );
}
