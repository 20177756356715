import React from "react";
import "twin.macro";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { StaticImage } from "gatsby-plugin-image";

export default function Footer() {
  function ScrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className="px-2">
      <section className="" tw="flex justify-center py-16 ">
        <button onClick={() => ScrollToTop()}>
          <StaticImage src="../../images/RD_Logo_Rot_Retina.png" alt="" />
        </button>
      </section>
      <section tw="bg-primary py-8" id="red">
        <div
          className=""
          tw="flex justify-center text-white text-sm font-medium"
        >
          <div tw="text-center">
            © Copyright{" "}
            <script>document.write(new Date().getFullYear());</script>
            2021{" "}
            <span tw="block md:inline">
              REALDESIGN | TEAM | All Rights Reserved |
              <Link to="/imprint">&nbsp;&nbsp;Impressum |&nbsp;</Link>
              <Link to="/dataprotection">Datenschutz</Link>
            </span>
          </div>
        </div>
        <div className="" tw="flex justify-center mt-6">
          <div>
            <a
              href="https://www.facebook.com/KochstudioMofongo"
              target="_blank"
              rel="noopener noreferrer"
              tw="text-white mr-2"
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.instagram.com/mofongokochstudio/"
              target="_blank"
              rel="noopener noreferrer"
              tw="text-white mx-2"
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCveWHPnz_uZSC0WPjev3-Fw"
              target="_blank"
              rel="noopener noreferrer"
              tw="text-white ml-2"
              aria-label="Youtube"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
